import { useEffect, useContext, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import GlobalContext from "GlobalContext";


export default function FinPrograms({ title , appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {
  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);

  const { app } = useContext(GlobalContext);
  const [err, setError] = useState(null);
  const [FilterHeightFinPrograms, setFilterHeight] = useState(499);

  useEffect(() => {
    app.model.waitForOpen.promise.then(() => {
      setTimeout(async () => {
        app.model.enigmaModel.evaluate("$(=count({1}distinct [_col_id_submissions]))").then(async (reply) => {
          const FinProgramsNumberOfFilterRows = reply*27+40;
          console.log('FinProgramsNumberOfFilterRows/Number of rows: ', FinProgramsNumberOfFilterRows);
          setFilterHeight(FinProgramsNumberOfFilterRows);
        }).catch(err => {
          if(!err) setError(err);
        });
      }, 500);
    });
  }, [app, err])

  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="YSuJJJY"
              height="30px"
              showLoader={false}
            />
          </div> */}
        </div>
      </div>
          
      {/* <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div> */}

      <div className="container-fluid">

        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

                {/* Table Row */}

                  {/* Table column */}
                      {/* Table */}
                      <QlikCard
                        id="5ad5ab40-707a-44c5-8b8b-37099bad8437"
                        // height="483px"
                        height="max(calc(100vh - 307px), 300px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        onBeforeExportHandler={async ({  }) => {
                          //console.log(data);
                            await app.variable.setStringValue('export_link', '1');
                        }}
                        onAfterExportHandler={async ({  }) => {
                            app.variable.setStringValue('export_link', '0');
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  {/* Columns Selector */}
                  <QlikCard
                    id="e254b0ca-7275-4734-8806-0b288bcdb6a4"
                    // height="485px"
                    height={`min(${FilterHeightFinPrograms}px - 25px, calc(100vh - 497px))`} //557"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />
                  {/* FilterPane */}
                  <QlikCard
                    id="b8a7c62f-9a4e-4f70-ae87-86556f96ea5d"
                    // height="485px"
                    height={`min(210px, calc(100vh - ${FilterHeightFinPrograms}px) + 20px) `}
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />

            </div>

          </div>

          {/* <div className="row mb-2">
            <div className="col-md-2 col-sm-3 col-2">
              <QlikObject
                id="d0140cc4-f1c2-422e-9a81-8cf750287d79"
                height="0px"
                showLoader={false}
              />
            </div>
          </div> */}

         
        </div>
    </section>
  );
}
